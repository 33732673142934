import React, { useCallback, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import FormattedMessage from "helpers/formattedMessage";
import useBranchLink from "../../../hooks/useBranchLink";
import MobileBanner from "../../MobileBanner";

import { goalMobileBannerInstallClick } from "../../../helpers/goalTracking";

import styles from "./Download.module.scss";

export interface Props {
  deepLink?: string;
  onClick?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
}

const Download = ({
  deepLink,
  onClick,
  onOpen,
  onClose,
}: Props): JSX.Element => {
  const { url, fetchUrl } = useBranchLink(deepLink);
  const [loading, setLoading] = useState(false);

  const handleUrlReady = useCallback(() => {
    if (!url) return;
    setLoading(false);
    window.location.href = url;
  }, [url]);

  const handleTimeout = useCallback(() => {
    if (!loading) return;

    setLoading(false);
    window.location.href = `${process.env.REACT_APP_BASE_URL}/download`;
  }, []);

  const handleClick = useCallback(
    async (event?: any) => {
      event && event.stopPropagation();
      if (loading) return;

      setTimeout(handleTimeout, 1000);
      setLoading(true);
      goalMobileBannerInstallClick();

      (url ? handleUrlReady : fetchUrl)();

      onClick && onClick();
    },
    [onClick]
  );

  useEffect(() => {
    if (url) {
      handleUrlReady();
    }
  }, [url]);

  const handleOpen = useCallback(() => {
    onOpen && onOpen();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    <MobileBanner
      sessionKey="download"
      className={styles.wrapper}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.text}>
          <span>
            <FormattedMessage
              id="components.mobilebanners.download.title"
              description="Banner that appears at the bottom of the mobile screen text"
              defaultMessage="Download Peanut for the full experience"
            />
          </span>
        </div>

        <div className={styles.button}>
          {loading ? (
            <BeatLoader color="#FFFFFF" size={8} />
          ) : (
            <FormattedMessage
              id="components.mobilebanners.download.install"
              description="Banner that appears at the bottom of the mobile screen, install button"
              defaultMessage="INSTALL"
            />
          )}
        </div>
      </div>
    </MobileBanner>
  );
};

export default Download;
