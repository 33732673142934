import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import usePortal from "react-useportal";
import classnames from "classnames";

import useSessionStorage from "../../hooks/useSessionStorage";

import styles from "./MobileBanner.module.scss";

export interface Props {
  children: React.ReactElement;
  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
  sessionKey: string;
}

const MobileBanner = ({
  children,
  className,
  onClose,
  onOpen,
  sessionKey,
}: Props): JSX.Element => {
  const [hasOpened, setHasOpened] = useState<boolean>(false);
  const [hidden, setHidden] = useSessionStorage(
    `mobileBanner-${sessionKey}`,
    false
  );

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const element = useMemo(
    () => typeof document !== "undefined" && document.getElementById("prompts"),
    [typeof document]
  );
  const { Portal } = usePortal({
    bindTo: element || undefined,
  });

  useEffect(() => {
    if (onOpen && isMobile && !hidden && !hasOpened) {
      onOpen();
      setHasOpened(true);
    }
  }, [isMobile, hidden, hasOpened]);

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setHidden(true);
      onClose && onClose();
    },
    [onClose]
  );

  return hidden ? (
    <></>
  ) : (
    <Portal>
      <div
        className={classnames(styles.wrapper, className, { isHidden: hidden })}
        data-testid="mobile-download-banner"
      >
        <div className={styles.close} onClick={handleClose} data-testid="close">
          <div>&times;</div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </Portal>
  );
};

export default MobileBanner;
