/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { goalTracking } from "../../../helpers/goalTracking";
import DownloadBanner from "../../../components/MobileBanners/Download";

interface IAltText {
  content: string;
  deepLink?: string;
}
interface IAltTextCollection {
  [key: string]: IAltText;
}

const ALT_TEXT: IAltTextCollection = {
  "/blog/cool-baby-boy-names": {
    content: "Looking for baby boy names? Download Peanut to find more",
    deepLink: "https://peanut.app.link/Nk6iiWata7",
  },
  "/blog/cool-baby-girl-names": {
    content: "Looking for baby girl names? Download Peanut to find more",
    deepLink: "https://peanut.app.link/Nk6iiWata7",
  },
  "/blog/chinese-baby-names": {
    content: "Looking for chinese baby names? Download Peanut to find more",
    deepLink: "https://peanut.app.link/Nk6iiWata7",
  },
  "/blog/indian-baby-boy-names": {
    content: "Looking for indian baby names? Download Peanut to find more",
    deepLink: "https://peanut.app.link/Nk6iiWata7",
  },
  "/blog/korean-baby-girl-names": {
    content: "Looking for korean baby names? Download Peanut to find more",
    deepLink: "https://peanut.app.link/Nk6iiWata7",
  },
  "/": {
    content:
      "Looking for friendship and support? Download Peanut to meet women like you",
  },
  "/blog/baby-names-that-mean-moon": {
    content: "Looking for baby names? Download Peanut to find more",
    deepLink: "https://peanut.app.link/Nk6iiWata7",
  },
  "/blog/negative-pregnancy-test-but-pregnant": {
    content: "Early pregnancy symptoms? Download Peanut for advice",
    deepLink: "https://peanut.app.link/YYuPkaS3G9",
  },
  "/blog/pregnancy-meal-plan": {
    content: "Looking for pregnancy meal plans? Download Peanut for advice ",
    deepLink: "hhttps://peanut.app.link/1KxJrVbta7",
  },
  "/blog/cervix-position": {
    content:
      "Looking to understand your cervix position? Download Peanut for advice",
    deepLink: "https://peanut.app.link/YYuPkaS3G9",
  },
  "/blog/can-you-get-pregnant-right-before-your-period": {
    content: "Looking for advice? Download Peanut to meet women like you",
    deepLink: "https://peanut.app.link/rPy6Frbta7",
  },
  "/blog/hcg-levels-by-week": {
    content:
      "Looking to understand pregnancy hormones? Download Peanut for advice",
    deepLink: "https://peanut.app.link/0BEnAKKmg7",
  },
  "/blog/10-dpo": {
    content:
      "Are you trying to conceive? Download Peanut to meet women like you",
    deepLink: "https://peanut.app.link/sC8aAqcta7",
  },
};

const VARIANT_CONTROL_TEXT: string = "VARIANT_CONTROL_TEXT";
const VARIANT_CONTEXTUAL_TEXT: string = "VARIANT_CONTEXTUAL_TEXT";

const MobileDownloadBanner = (): JSX.Element => {
  const { pathname } = useLocation();
  const [altText, setAltText] = useState<IAltText | undefined>();
  const [trackedEvents, setTrackedEvents] = useState<string[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const variant = VARIANT_CONTROL_TEXT;

  const trackEvent = useCallback(
    (event: "open" | "close" | "click") => {
      if (variant && !trackedEvents.includes(event)) {
        const variantType =
          variant === VARIANT_CONTEXTUAL_TEXT ? "hypothesis" : "control";
        setTrackedEvents([...trackedEvents, event]);

        goalTracking(`xp_contextual_banner_3_${variantType}_${event}`);
      }
    },
    [variant, trackedEvents]
  );

  useEffect(() => {
    if (variant === VARIANT_CONTEXTUAL_TEXT) {
      setAltText(ALT_TEXT[pathname]);
    }
  }, [pathname, variant]);

  useEffect(() => {
    if (isOpen && variant) {
      trackEvent("open");
    }
  }, [isOpen, variant]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [variant]);

  const handleClick = useCallback(() => {
    trackEvent("click");
  }, [variant]);

  const handleClose = useCallback(() => {
    trackEvent("close");
  }, [variant]);

  const { deepLink } = altText || {};

  return (
    <DownloadBanner
      deepLink={deepLink}
      onOpen={handleOpen}
      onClick={handleClick}
      onClose={handleClose}
    />
  );
};

export default MobileDownloadBanner;
